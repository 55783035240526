<template>
  <div class="sm:py-10 sm:px-10 px-2 py-10">
    <n-card no-padding>
      {{ /* FAQ Header, title and category selector */ }}
      <div class="flex flex-col lg:flex-row justify-between pt-6 px-6">
        <h1
          class="my-auto font-serif text-brand-purple-500 font-semibold text-2xl"
        >
          <n-html :text="$t('service.faq')" />
        </h1>

        <div
          class="mt-4 lg:mt-0 p-4 bg-gray-100 rounded font-serif text-sm sm:block hidden"
        >
          <button
            :class="`mx-3 py-1 cursor-pointer ${active()}`"
            @click="setCategory()"
          >
            <n-html :text="$t('service.categories.all')" />
          </button>
          <button
            :class="`mx-3 py-1 cursor-pointer ${active('insurance')}`"
            @click="setCategory('insurance')"
          >
            <n-html :text="$t('service.categories.insurance')" />
          </button>
          <button
            :class="`mx-3 py-1 cursor-pointer ${active('damage')}`"
            @click="setCategory('damage')"
          >
            <n-html :text="$t('service.categories.damage')" />
          </button>
          <button
            :class="`mx-3 py-1 cursor-pointer ${active('theft')}`"
            @click="setCategory('theft')"
          >
            <n-html :text="$t('service.categories.theft')" />
          </button>
        </div>
      </div>
      {{ /* FAQ item cards */ }}
      <div class="sm:block hidden">
        <div class="flex flex-col mt-4 pb-6 px-6">
          <card-faq-item
            v-for="(item, index) in filteredItems"
            :item="item"
            :key="index"
            class="my-1"
          />
          <card-faq-delete-info class="my-1" />
          <card-faq-delete-account class="my-1" />
        </div>
      </div>
      <div class="sm:hidden">
        <div class="flex flex-col mt-4">
          <card-faq-subheader :text="$t('service.categories.insurance')" />
          <div class="px-6 py-4">
            <card-faq-item
              v-for="(item, index) in insuranceItems"
              :item="item"
              :key="index"
              class="my-3"
            />
          </div>
          <card-faq-subheader :text="$t('service.categories.damage')" />
          <div class="px-6 py-4">
            <card-faq-item
              v-for="(item, index) in damageItems"
              :item="item"
              :key="index"
              class="my-3"
            />
          </div>
          <card-faq-subheader :text="$t('service.categories.theft')" />
          <div class="px-6 py-4">
            <card-faq-item
              v-for="(item, index) in theftItems"
              :item="item"
              :key="index"
              class="my-3"
            />
            <card-faq-delete-info class="my-3" />
            <card-faq-delete-account class="my-3" />
          </div>
        </div>
      </div>
    </n-card>
  </div>
</template>

<script>
import CardFaqItem from "../../components/service/card-faq-item";
import CardFaqDeleteInfo from "../../components/service/card-faq-delete-info";
import CardFaqDeleteAccount from "../../components/service/card-faq-delete-account";
import CardFaqSubheader from "../../components/service/card-faq-subheader";

export default {
  name: "faq",
  components: {
    CardFaqItem,
    CardFaqDeleteAccount,
    CardFaqDeleteInfo,
    CardFaqSubheader
  },
  data() {
    return {
      category: ""
    };
  },
  created() {
    this.$store.dispatch("faq/getAll").catch(() => (this.error = true));
  },
  computed: {
    insuranceItems() {
      const faqs = this.$store.getters["faq/getAll"];
      return faqs.filter(i => i.category === "insurance");
    },
    damageItems() {
      const faqs = this.$store.getters["faq/getAll"];
      return faqs.filter(i => i.category === "damage");
    },
    theftItems() {
      const faqs = this.$store.getters["faq/getAll"];
      return faqs.filter(i => i.category === "theft");
    },
    filteredItems() {
      const faqs = this.$store.getters["faq/getAll"];
      let filteredFaqs = this.category
        ? faqs.filter(i => i.category === this.category)
        : faqs;

      filteredFaqs = filteredFaqs.filter((item, index, self) => {
        return (
          index ===
          self.findIndex(
            i => i.question.toLowerCase() === item.question.toLowerCase()
          )
        );
      });
      return filteredFaqs;
    }
  },
  methods: {
    active(category = "") {
      return category === this.category
        ? "border-b-2 border-purple-500"
        : "opacity-75";
    },
    setCategory(category = "") {
      this.category = category;
    }
  }
};
</script>

<style scoped></style>
