<template>
  <div
    class="padding-top-grid-between padding-left-grid-between padding-right-grid-between padding-bottom-grid-between"
  >
    {{/* Dashboard head including image with decoration */}}
    <div class="col-span-12 w-full grid grid-cols-12 overflow-x-hidden">
      {{/* Text left side */}}
      <div
        class="bg-brand-light-purple-100 col-span-12 xl:col-span-6 flex z-10"
      >
        <div class="w-full mx-auto px-8 sm:px-12 md:px-16 py-12">
          <h1
            class="font-serif uppercase text-brand-purple-500 text-3xl font-extrabold"
          >
            <n-html :text="$t('service.title')" />
          </h1>
          <p class="font-serif leading-7 mb-7 mt-10">
            <n-html :text="$t('service.subtitle')" />
          </p>
          <n-button-regular @click.native="showContactForm"
            ><n-html :text="$t('service.contact_form')"
          /></n-button-regular>
        </div>
      </div>
      {{/**/}}
      {{/* skewed decoration for dashboard in front of image right side */}}
      <div
        class="hidden xl:grid grid-cols-12 col-span-6 bg-dashboard-greeting bg-cover bg-right"
      >
        <div
          class="col-span-1 bg-brand-light-purple-100 p-4 origin-top transform skew-x-12 -ml-96"
        />
        <div class="col-span-1 flex origin-top transform skew-x-12">
          <div class="bg-brand-light-purple-transparent-100 p-5 ml-8" />
          <div class="bg-brand-light-purple-transparent-100 p-2.5 ml-8" />
        </div>
      </div>
      {{/**/}}
    </div>
    <container-faq />
    <modal-contact-form
      :show="contact_form_visible"
      :onCloseModal="closeContactForm"
    />
  </div>
</template>

<script>
import validation from "../../mixins/validation";
import ContainerFaq from "../../components/service/container-faq";
import ModalContactForm from "../../components/service/modal-contact-form";

export default {
  name: "pages.service.index",
  mixin: [validation],
  components: {
    ContainerFaq,
    ModalContactForm
  },
  data() {
    return {
      service_numbers: [
        {
          label: "NL",
          number: "010 - 206 86 86"
        },
        {
          label: "BE",
          number: "078 - 48 37 93"
        }
      ],
      contact_form_visible: false
    };
  },
  methods: {
    showContactForm() {
      this.contact_form_visible = true;
    },
    closeContactForm() {
      this.contact_form_visible = false;
    }
  }
};
</script>
