<template>
  <div
    class="rounded-lg w-full cursor-pointer border"
    :class="
      `${
        collapsed
          ? 'border-brand-gray-300'
          : 'border-brand-gray-400 border-l-brand-purple-500'
      }`
    "
    @click="collapsed = !collapsed"
  >
    <div class="flex flex-row">
      <div class="w-2 bg-brand-purple-500 rounded-l" v-if="!collapsed" />
      <div class="flex flex-col w-full py-5">
        <div
          class="w-full"
          :class="`${!collapsed ? 'pl-3 pr-5 pb-5' : 'px-5'}`"
        >
          <div class="flex flex-row justify-between font-semibold">
            {{ item[`question${translationSuffix}`] }}
            <n-icon class="pl-6" :name="collapseIcon()" />
          </div>
        </div>
        <div v-if="!collapsed" class="border-t ml-3 mr-5 pt-5">
          <p
            class="typography-p-regular margin-bottom-none"
            v-html="item[`answer${translationSuffix}`]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "faq-card",
  props: {
    item: Object
  },
  data: () => ({
    collapsed: true
  }),
  computed: {
    translationSuffix() {
      let translationSuffix = `_${this.$i18n.locale.substring(0, 2)}`;
      return !this.item[`question${translationSuffix}`] ||
        !this.item[`answer${translationSuffix}`]
        ? `_${this.$i18n.fallbackLocale.substring(0, 2)}`
        : translationSuffix;
    }
  },
  methods: {
    collapseIcon() {
      return !this.collapsed ? "Chevron-up" : "Chevron-down";
    }
  }
};
</script>

<style scoped></style>
