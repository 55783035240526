<template>
  <div
    class="rounded-lg w-full cursor-pointer border"
    :class="
      `${
        collapsed
          ? 'border-brand-gray-300'
          : 'border-brand-gray-400 border-l-brand-purple-500'
      }`
    "
  >
    <div class="flex flex-row">
      <div class="w-2 bg-brand-purple-500 rounded-l" v-if="!collapsed" />
      <div class="flex flex-col w-full py-5">
        <div
          class="w-full"
          :class="`${!collapsed ? 'pl-3 pr-5 pb-5' : 'px-5'}`"
          @click="collapsed = !collapsed"
        >
          <div class="flex flex-row justify-between font-semibold">
            <n-html :text="$t('service.account.question')" />
            <n-icon class="pl-6" :name="collapseIcon()" />
          </div>
        </div>
        <div v-if="!collapsed" class="border-t ml-3 mr-5 pt-5">
          <p class="typography-p-regular margin-bottom-none">
            <n-html :text="$t('service.account.answer')" />
          </p>
          <n-button-link
            class="ml-auto"
            variant="cancel"
            @click.native="isDeletingAccount = true"
            ><n-html :text="$t('service.account.action')" />
          </n-button-link>
        </div>
      </div>
    </div>
    <modal
      v-if="isDeletingAccount"
      :title="$t('service.account.modal.delete_title')"
      @close="isDeletingAccount = false"
    >
      <p><n-html :text="$t('service.account.modal.delete_warning')" /></p>
      <template v-slot:footer>
        <n-button-link bold @click.native="isDeletingAccount = false">
          <n-html :text="$t('form.common.cancel')" />
        </n-button-link>
        <n-button-regular @click.native="deleteAccount">
          <n-html :text="$t('form.common.confirm')" />
        </n-button-regular>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/theme/modal/modal";
export default {
  name: "faq-card-delete",
  components: {
    Modal
  },
  data: () => ({
    collapsed: true,
    isDeletingAccount: false
  }),
  methods: {
    collapseIcon() {
      return !this.collapsed ? "Chevron-up" : "Chevron-down";
    },
    deleteAccount() {
      this.$store.dispatch("user/request_customer_account_deletion");
      this.isDeletingAccount = false;
    }
  }
};
</script>

<style scoped></style>
