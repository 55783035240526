<template>
  <modal
    :class="show ? '' : 'hidden'"
    @close="onClose"
    :title="$t('service.form.title')"
    small
    no-footer
  >
    <div v-if="form_states.form">
      <div class="flex flex-row">
        <n-icon class="mr-2" name="Info" small />
        <p class="font-semibold text-brand-purple-500">
          <n-html :text="$t('service.form.success.title')" />
        </p>
      </div>

      <p class="mt-2"><n-html :text="$t('service.form.success.subtitle')" /></p>
    </div>
    <div class="pt-2" v-else>
      <div class="pb-4">
        <n-html :text="$t('service.form.subtitle')" />
      </div>
      <p class="font-semibold pt-2 pb-2">
        <n-html :text="$t('form.service_message.label')" />
      </p>
      <n-form-group id="input-group-message">
        <n-form-input-default
          id="input-message"
          :placeholder="$t('form.service_message.placeholder')"
          v-model="message"
          field_type="textarea"
          rows="3"
          no-resize
        />
      </n-form-group>
      <n-form-group
        id="button-group-form-message"
        :invalid-feedback="$t(this.invalidFeedback)"
        :state="form_states.form"
      >
        <div class="flex flex-row-reverse w-full pt-4">
          <n-button-regular @click.native="send" :disabled="!valid">
            <n-html :text="$t('service.form.button')" />
          </n-button-regular>
        </div>
      </n-form-group>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/theme/modal/modal";

export default {
  name: "modal-contact-form",
  props: {
    onCloseModal: Function,
    show: Boolean
  },
  components: {
    Modal
  },
  data: () => ({
    message: "",
    form_states: {
      form: null,
      error: ""
    }
  }),
  computed: {
    valid() {
      return !!this.message && this.validateNotEmpty(this.message);
    },
    invalidFeedback() {
      return this.form_states.error === "MESSAGE_TOO_LONG"
        ? "service.form.error.too_long"
        : "details.common.forms.error";
    },
  },
  methods: {
    send() {
      if (this.message.length > 500) {
        // server can only handle up to 500 chars
        this.form_states = { form: false, error: "MESSAGE_TOO_LONG" };
        return;
      }
      this.form_states.form = null;
      this.$store
        .dispatch("contact/question", {
          message: this.message,
        })
        .then(() => (this.form_states.form = true))
        .catch(
          () => (this.form_states = { form: false, error: "NETWORK_ERROR" })
        );
    },
    onClose() {
      this.form_states.form = null;
      this.message = "";
      this.onCloseModal();
    },
  },
};
</script>

<style scoped></style>
