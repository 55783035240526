<template>
  <div class="w-full bg-brand-gray-100">
    <h2 class="my-auto font-serif text-black font-semibold text-md my-4 mx-6">
      <n-html :text="text" />
    </h2>
  </div>
</template>

<script>
export default {
  name: "card-faq-subheader",
  props: {
    text: String
  }
};
</script>

<style scoped></style>
